const LoginSubmitButtonMixin = {
    data: () => ({
        submitDisableButtonColor: '#B3B3B3',
        submitEnableButtonColor: '#EE7100',

    }),
    methods: {
        setSubmitButtonDisable() {
            this.submitDisableButtonColor = '#B3B3B3';
        },
        setSubmitButtonEnable() {
            this.submitDisableButtonColor = '#EE7100';
        },
        changeColorByObserver(){
            if(this.$refs.observer.validate()){
                this.setSubmitButtonEnable();
            } else{
                this.setSubmitButtonDisable();
            }
        }
    }
}

export default LoginSubmitButtonMixin